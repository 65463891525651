import React from "react";
import { Container, Grid } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useStyles } from "../themes/theme";

import Layout from "../components/Layout/Layout";

export const ProfileComponent = () => {
  const classes = useStyles();

  const { user } = useAuth0();

  return (
    <Layout>
      <Container className={classes.formContainer}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <h2>{user.name}</h2>
            <p className="lead text-muted">{user.email}</p>
          </Grid>

          <Grid item xs={12}>
            <SyntaxHighlighter language="javascript" style={atomDark}>
              {JSON.stringify(user, null, 2)}
            </SyntaxHighlighter>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default ProfileComponent;
