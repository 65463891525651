import moment from "moment";

// figures out how long it's between two dates and returns different color={x}
//
function timeDiffColor(
  datetime,
  dateFormat = "YYYYMMDDHHmm",
  time = "hours",
  number = 12
) {
  const timeNow = moment().locale("sv").format(dateFormat);
  const condition =
    moment(datetime, dateFormat).diff(moment(timeNow, dateFormat), time) -
    number;
  if (condition > number) {
    return "success";
  } else if (condition < number && condition > 0) {
    return "warning";
  } else {
    return "error";
  }
}

export default timeDiffColor;
