import * as Yup from "yup";

// Yup form validation
export const upgradeSchema = Yup.object().shape({
  toggleAll: Yup.bool(),
  dateInput: Yup.string().required("Date is required."),
  timeInput: Yup.string().required("Time is required."),
  clusterSelect: Yup.string().required("You must select an cluster"),
  selectedCustomers: Yup.array()
    .of(Yup.string())
  .required("At least one customer must be selected")
  .min(1, "At least one customer must be selected"),
  jiraTicket: Yup.string()
    .matches(
      /^PD-[0-9]{3,4}$|^-$/,
      "Must start with PD- and contain 3 or 4 digits. If no Jira ticket # available then use dash '-'."
    )
    .required('Jira Ticket input is required. Use "-" if no ticket to attach'),
    pcsReleaseSelect: Yup.string()
    .matches(
      /^[0-9]\.[0-9]+\.[0-9]+$/,
      "Must contain digits followed by '.' Example 4.13.7. No support for versions ending with characters."
    )
});

export const upgradeInitialValues = {
  dateInput: "",
  timeInput: "",
  clusterSelect: "",
  selectedCustomers: [],
  jiraTicket: "",
  upgradeType: "standard",
  pcsReleaseSelect: "",
  databaseBackup: true,
  bsCheck: false,
  upgradeSpecialCustomer: false,
  setNotificationBanner: true,
  toggleAll: false,
};
