import { Autocomplete, FormControl, InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetPCSReleases, useGetPCSReleasesDefault } from "../../utils/crudFetch";

export function SelectPCSReleaseCol({ formik, classes, configState }) {
  const { data: pcsReleases } = useGetPCSReleases(null, configState.AUTH0_TOKEN);
  const { data: pcsReleasesDefault } = useGetPCSReleasesDefault(null, configState.AUTH0_TOKEN);

  const [options, setOptions] = useState([]);
  const [defaultValue, setdefaultValue] = useState("");
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (typeof pcsReleases === "object" && pcsReleases.data) {
      setOptions(pcsReleases.data);
    }
  }, [pcsReleases]);

  useEffect(() => {
    if (typeof pcsReleasesDefault === "object" && pcsReleasesDefault.data) {
      setdefaultValue(pcsReleasesDefault.data);

      // update defult value if formik value is empty
      if (formik.values.pcsReleaseSelect === "") {
        formik.setFieldValue("pcsReleaseSelect", pcsReleasesDefault.data);
      }
    }
  }, [pcsReleasesDefault]);

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={
        formik.touched.pcsReleaseSelect && Boolean(formik.errors.pcsReleaseSelect)
      }
      className={classes.formControlSelect}
    >
      <InputLabel shrink className={classes.customInputLabel}>
        {formik.touched.pcsReleaseSelect && formik.errors.pcsReleaseSelect
          ? formik.errors.pcsReleaseSelect
          : "Select PCS Version"}
      </InputLabel>
      <Autocomplete
        id="pcsReleaseSelect"
        name="pcsReleaseSelect"
        size="small"
        // set freeSolo to true to allow user to input a value that is not in the options
        freeSolo={true}
        options={options}
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
          formik.setFieldValue("pcsReleaseSelect", newValue === null ? defaultValue : newValue);
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
          setValue(newInputValue);
          formik.setFieldValue("pcsReleaseSelect", newInputValue === null ? defaultValue : newInputValue);
        }}
        onBlur={formik.handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps
            }}
            placeholder={`${defaultValue} (default)`}
            sx={{
              size: "small",
              variant: "outlined",
            }}
          />
        )}
      />
    </FormControl>
  );
}
