import React from "react";
import { Container, Box } from "@mui/material";

function Layout({ children, config, isAuthenticated }) {
  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mt: 10,
        }}
      >
        {children}
      </Box>
    </Container>
  );
}

export default Layout;
