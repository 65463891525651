function getCustomersByCluster(object, value) {
  const matched = object.find((item) => item.cluster === value);
  return matched ? matched.customers : [];
}

function getCustomersByApp(object, value) {
  const matched = object.find((item) => item.app === value);
  return matched ? matched.customers : [];
}

export {getCustomersByCluster, getCustomersByApp};
