import * as Yup from "yup";

// Yup form validation
export const bannerSchema = Yup.object().shape({
  toggleAll: Yup.bool(),
  dateInput: Yup.string().when("schedule", {
    is: true,
    then: Yup.string().required("Date is required."),
  }),
  timeInput: Yup.string().when("schedule", {
    is: true,
    then: Yup.string().required("Time is required."),
  }),
  // clusterSelect: Yup.string().required("You must select an cluster"),
  selectedCustomers: Yup.array().when("toggleAll", {
    is: false,
    then: Yup.array()
      .min(1, "At least one customer needs to be selected")
      .required("Customer selection is required."),
  }),
  bannerTitle: Yup.string().when("deleteNotificationBanner", {
    is: false,
    then: Yup.string().required("Banner title is required."),
  }),
  bannerDescription: Yup.string().when("deleteNotificationBanner", {
    is: false,
    then: Yup.string().required("Banner description is required."),
  }),
  bannerType: Yup.string().optional().default("info"),
});

export const bannerInitialValues = {
  dateInput: "",
  timeInput: "",
  clusterSelect: "",
  appSelect: "",
  selectedCustomers: [],
  bannerTitle: "",
  bannerApp: "",
  bannerDescription: "",
  bannerType: "info",
  schedule: false,
  deleteNotificationBanner: false,
  toggleAll: false,
};
