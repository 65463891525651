import React from "react";
import { FormControl, TextField, InputLabel } from "@mui/material";

function TextInput({
  formik: { handleBlur, handleChange, values, touched, errors },
  classes,
  label,
  helperText,
  required = true,
  multiline = false,
  rows = 1,
  disabled = false,
}) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required={required}
      className={
        disabled
          ? `${classes.formControlSelect} ${classes.formControlDisabled}`
          : classes.formControlSelect
      }
      disabled={disabled}
      error={touched[label] && Boolean(errors[label])}
    >
      <InputLabel shrink className={classes.customInputLabel}>
        {touched[label] && errors[label] ? errors[label] : helperText}
      </InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        id={label}
        name={label}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[label]}
        className={classes.root}
        autoComplete="off"
        multiline={multiline}
        rows={rows}
        disabled={disabled}
      />
    </FormControl>
  );
}

export default TextInput;
