import { Chip } from "@mui/material";
import { NavLink as RouterNavLink } from "react-router-dom";
import { memo } from "react";

export const NavLink = memo(
  ({ active, label, to, icon: Icon, externalLink = false }) => {
    const linkProps = externalLink
      ? {
          href: to,
          component: "a",
          target: "_blank",
          rel: "noopener noreferrer",
        }
      : { to, component: RouterNavLink };

    return (
      <Chip
        sx={{
          bgcolor: active ? "#F9FCFC" : "primary.main",
          color: "#1C1B1F",
          fontSize: 14,
          fontWeight: 600,
          cursor: "pointer",
          fontStyle: "normal",
          letterSpacing: " 0.1px",
          padding: "7px 15px",
          ":hover": {
            color: "#1C1B1F",
            padding: "7px 15px",
          },
        }}
        label={label}
        icon={<Icon color="#1C1B1F" sx={{ fontSize: "14px" }} />}
        {...linkProps}
      />
    );
  }
);
