import React, { useContext } from "react";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  Divider,
  Grid,
} from "@mui/material";
import TextInput from "../Form/TextInput";
import { useFormik } from "formik";
import { bannerSchema, bannerInitialValues } from "../../validations/Banner";
import { useHistory } from "react-router-dom";
import { SelectClusterCol } from "../Form/SelectClusterCol";
import { SelectDateCol } from "../Form/SelectDateCol";
import { SelectTimeCol } from "../Form/SelectTimeCol";
import { SelectCustomerCol } from "../Form/SelectCustomerCol";
import GreenSwitch from "../Form/GreenSwitch";
import {usePostBatchDeleteBanner, usePostScheduleBannerJob, usePostSetBanner} from "../../utils/crudFetch";
import { useStyles } from "../../themes/theme";
import moment from "moment/moment";
import { ConfigContext } from "../../ContextProvider";
import {useQueryClient} from "react-query";
import {SelectAppCol} from "../Form/SelectAppCol";

export const BannerForm = ({ clusters, apps }) => {
  const [configState] = useContext(ConfigContext);

  const classes = useStyles();
  const history = useHistory();
  const { mutateAsync: mutateAsyncSchedule } = usePostScheduleBannerJob(null, configState.AUTH0_TOKEN);
  const { mutateAsync: mutateAsyncSet } = usePostSetBanner(null, configState.AUTH0_TOKEN);
  const queryClient = useQueryClient();
  const { mutateAsync: mutateAsyncBatchDelete } = usePostBatchDeleteBanner(queryClient, null, configState.AUTH0_TOKEN);

  const postJobs = async (data) => {
    await mutateAsyncSchedule(
      { data },
      {
        onSuccess: () => {
          formik.setStatus({
            sent: true,
            msg: "Banner job created, redirecting ...",
          });
        },
        onError: (err) => {
          // display error message
          formik.setStatus({
            sent: false,
            msg: err.message + " " + err.request.statusText,
          });
        },
      }
    );
  };

  const postSetBanner = async (data) => {
    await mutateAsyncSet(
        { data },
        {
          onSuccess: () => {
            formik.setStatus({
              sent: true,
              msg: "Banner job created, redirecting ...",
            });
          },
          onError: (err) => {
            // display error message
            formik.setStatus({
              sent: false,
              msg: err.message + " " + err.request.statusText,
            });
          },
        }
    );
  };

  const postBatchDeleteBanner = async (data) => {
    await mutateAsyncBatchDelete(
        { data },
        {
          onSuccess: () => {
            formik.setStatus({
              sent: true,
              msg: "Banner job created, redirecting ...",
            });
          },
          onError: (err) => {
            // display error message
            formik.setStatus({
              sent: false,
              msg: err.message + " " + err.request.statusText,
            });
          },
        }
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: bannerInitialValues,
    onSubmit: async (values) => {
      // inputs as backend wants it
      let customer_list = [];

      if (values.toggleAll) {
        customer_list = ["ALL"];
      } else {
        customer_list = values.selectedCustomers;
      }
      if (values.schedule) {
        const postBody = {
          app: values.bannerApp,
          cluster: values.clusterSelect,
          customer_list: customer_list,
          message: values.bannerDescription,
          title: values.bannerTitle,
          delete_notification: values.deleteNotificationBanner,
          date:
              moment(values.dateInput).format("YYYYMMDD") +
              moment(values.timeInput, "HH:mm").format("HHmm"),
          banner_type: values.bannerType,
        };
        await postJobs(postBody);
      } else {
        if (values.deleteNotificationBanner) {
          const postBody = {
            app: values.bannerApp,
            customer_list: customer_list,
          };
          await postBatchDeleteBanner(postBody)
        } else {
          const postBody = {
            app: values.bannerApp,
            customer_list: customer_list,
            message: values.bannerDescription,
            title: values.bannerTitle,
            banner_type: values.bannerType,
          };
          await postSetBanner(postBody)
        }
      }

      setTimeout(() => {
        // redirect to get-jobs page in 2000 ms (2 seconds)
        history.replace(values.schedule ? "/get-jobs" : "/get-banners");
      }, 2000);

      formik.setSubmitting(false);
    },

    validationSchema: bannerSchema,
  });

  return (
    <Container className={classes.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mb: 2,
          }}
        >
          <Grid item style={{ paddingLeft: "10px" }}>
            <h2 className={classes.heading}>Banner</h2>
          </Grid>
          <Grid item>
            <FormControlLabel
                control={
                  <GreenSwitch
                      name="schedule"
                      onChange={formik.handleChange}
                      checked={formik.values.schedule}
                  />
                }
                label="Schedule"
                labelPlacement="start"
            />
            <FormControlLabel
              control={
                <GreenSwitch
                  name="deleteNotificationBanner"
                  onChange={formik.handleChange}
                  checked={formik.values.deleteNotificationBanner}
                />
              }
              label="Remove Banner"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row">
          <Grid item xs={4}>
            <SelectClusterCol
              formik={formik}
              customerList={clusters}
              classes={classes}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectAppCol
                formik={formik}
                appList={apps}
                classes={classes}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectCustomerCol
              formik={formik}
              customerList={clusters}
              appList={apps}
              classes={classes}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectDateCol formik={formik} classes={classes} disabled={!formik.values.schedule}/>
          </Grid>
          <Grid item xs={4}>
            <SelectTimeCol formik={formik} classes={classes} disabled={!formik.values.schedule}/>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              formik={formik}
              classes={classes}
              label="bannerTitle"
              helperText="Banner Title"
              validate={formik.validateField}
              disabled={formik.values.deleteNotificationBanner}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              variant="outlined"
              required
              className={classes.formControl}
            >
              <InputLabel
                shrink
                className={
                  formik.values.deleteNotificationBanner
                    ? `${classes.customInputLabel} ${classes.customInputLabelDisabled}`
                    : classes.customInputLabel
                }
              >
                Banner Type
              </InputLabel>
              <Select
                labelId="banner-type-select-label"
                id="banner-type-select"
                name="bannerType"
                size="small"
                value={formik.values.bannerType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                disabled={formik.values.deleteNotificationBanner}
              >
                <MenuItem value="info">Info</MenuItem>
                <MenuItem value="warning">Warning</MenuItem>
                <MenuItem value="error">Error</MenuItem>
                <MenuItem value="success">Success</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              formik={formik}
              classes={classes}
              label="bannerDescription"
              helperText="Description"
              validate={formik.validateField}
              multiline={true}
              rows={4}
              disabled={formik.values.deleteNotificationBanner}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "16px 0", backgroundColor: "#d9d9d9" }} />

        <Grid container alignItems="flex-end" justifyContent="end">
          <Grid item>{/* empty item to fill the left side */}</Grid>
          <Grid item>
            <Button
              id="submit-button"
              type="submit"
              variant="contained"
              color={!formik.isValid || !formik.dirty ? "primary" : "success"}
              className={classes.submitButton}
              style={{ textTransform: "none" }}
              disabled={!formik.isValid || !formik.dirty}
            >
              {formik.values.schedule ? "Schedule" : formik.values.deleteNotificationBanner ? "Remove Now" : "Set Now" }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default BannerForm;
