import React from "react";
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

export const ExportDataButton = ({ handleExport }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        color="success"
        size="small"
        onClick={handleClick}
        variant="contained"
        endIcon={<KeyboardArrowDown />}
      >
        Export Data
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleExport("csv")}>
          <ListItemIcon>
            <Typography variant="inherit">CSV</Typography>
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => handleExport("json")}>
          <ListItemIcon>
            <Typography variant="inherit">JSON</Typography>
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
