import { useGetBanners } from "../utils/crudFetch";
import React, { useMemo, useContext } from "react";
import MaterialReactTable, {
  MRT_GlobalFilterTextField,
  MRT_ToolbarInternalButtons,
} from "material-react-table";

import {
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  Container,
} from "@mui/material";
import DeleteButton from "../components/DeleteButton";
import { useDeleteBanner } from "../utils/crudFetch";
import { useQueryClient } from "react-query";
import { BannersColumns } from "../components/Banner/BannersColumns";
import { ConfigContext } from "../ContextProvider";

export const GetBanners = () => {
  const [configState] = useContext(ConfigContext);

  const { data: banners, isError, isLoading } = useGetBanners(null, configState.AUTH0_TOKEN);

  const theme = useTheme();
  const queryClient = useQueryClient();
  const deleteMutation = useDeleteBanner(queryClient, configState.AUTH0_TOKEN);

  const columns = useMemo(() => BannersColumns(), []);

  const formattedData =
    typeof banners === "object" && banners.data
      ? Object.values(banners.data)
      : [];

  // Determine the height of the spacer based on the screen size and the `size` property of the `Tabs` component
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const tabsHeight = isSmallScreen ? 8 : 18;
  const tableWidth = isSmallScreen ? "md" : "lg";
  return (
    <Box>
      <Container maxWidth={tableWidth}>
        <Box sx={{ height: tabsHeight }} />

        <MaterialReactTable
          usePaperWrapper={false}
          enableStickyHeader
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "1px",
              backgroundColor: theme.palette.background.main,
            },
          }}
          muiTableContainerProps={{
            sx: {
              backgroundColor: "#fff",
              borderRadius: "1px",
              border: `1px solid ${theme.palette.divider}`,
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              borderRadius: "1px",
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: "#fff",
            },
          }}
          muiTableHeadProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          muiTableHeadRowProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          columns={columns}
          data={formattedData}
          defaultColumn={{
            maxSize: 200,
            minSize: 120,
          }}
          positionGlobalFilter="left"
          enableDensityToggle={false}
          showToolbarDropZone={false}
          initialState={{
            density: "compact",
            pagination: { pageSize: 15 },
          }}
          renderTopToolbar={({ table }) => (
            <React.Fragment>
              <Box display="flex" justifyContent="space-between" sx={{ py: 2 }}>
                <Box display="flex" justifyContent="flex-start">
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ToolbarInternalButtons table={table} />
                </Box>
              </Box>
              <Divider />
            </React.Fragment>
          )}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Remove",
            },
          }}
          enableRowActions={true}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <Box>
              <DeleteButton
                item={ {customer: row.original.customer, app: row.original.app} }
                mutation={deleteMutation}
              />
            </Box>
          )}
          state={{ isLoading }}
          showAlertBanner={isError}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: "error loading data",
                }
              : undefined
          }
        />
      </Container>
    </Box>
  );
};

export default GetBanners;
