import { useContext } from "react";
import Box from "@mui/material/Box";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PermDeviceInformationOutlinedIcon from "@mui/icons-material/PermDeviceInformationOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import { useLocation } from "react-router-dom";
import { NavLink } from "./NavLink";
import { useAuth0 } from "@auth0/auth0-react";
import {ListAltOutlined} from "@mui/icons-material";
import { ConfigContext } from "../../ContextProvider";

function SubBar({ children, config }) {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth0();
  const [configState] = useContext(ConfigContext);

  const hasPermission =
    isAuthenticated &&
    config.ALLOWED_GROUPS &&
    user &&
    config.ALLOWED_GROUPS.some((group) =>
      user["https://pricer.com/groups"].includes(
        String(group).toLocaleLowerCase()
      )
    );

  return (
    <Box bgcolor={"primary.main"} height="56px ">
      <Box
        display="flex"
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          minHeight: "56px",
          paddingInline: 4,
        }}
      >
        {hasPermission && (
          <NavLink
            active={location.pathname === "/"}
            label="Create jobs"
            to="/"
            icon={DashboardOutlinedIcon}
          />
        )}

        <NavLink
          active={location.pathname === "/customer-information"}
          label="Customer info"
          to="/customer-information"
          icon={PermDeviceInformationOutlinedIcon}
        />

        {hasPermission && (
          <NavLink
            active={location.pathname === "/get-jobs"}
            label="Scheduled jobs"
            to="/get-jobs"
            icon={DateRangeOutlinedIcon}
          />
        )}

        {hasPermission && (
          <NavLink
            active={location.pathname === "/get-banners"}
            label="Banners"
            to="/get-banners"
            icon={ListAltOutlined}
          />
        )}

        {hasPermission && (
          <NavLink
            active={location.pathname === "/get-pcs-releases"}
            label="PCS Releases"
            to="/get-pcs-releases"
            icon={ListAltOutlined}
          />
        )}
        
        <NavLink
            label="Swagger"
            to={`${configState.REACT_APP_BACKEND_URL}/docs/`}
            icon={LaunchOutlinedIcon}
            externalLink={true}
          />
      </Box>
    </Box>
  );
}

export default SubBar;
