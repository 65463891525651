import React from "react";
import {
  MRT_GlobalFilterTextField,
  MRT_ToolbarInternalButtons,
} from "material-react-table";
import { Box, Divider } from "@mui/material";
import { ExportDataButton } from "./ExportDataButton";

export const CustomTopToolbar = ({ table, handleExportData }) => {
  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" sx={{ py: 2 }}>
        <Box display="flex" justifyContent="flex-start">
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToolbarInternalButtons table={table} />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexGrow={1}
          sx={{
            mx: 2,
          }}
        >
          <ExportDataButton
            handleExport={(format) => handleExportData(format)}
          />
        </Box>
      </Box>
      <Divider />
    </React.Fragment>
  );
};
