import * as Yup from "yup";

export const customerSchema = Yup.object().shape({
  clusterSelect: Yup.string().required("You must select an cluster"),
  customerProfile: Yup.string().when("removeCustomer", {
    is: false,
    then: Yup.string().required("Customer profile is required."),
  }),
  pcsVersion: Yup.string()
    .matches(
      /^[0-9]\.[0-9]+\.[0-9]+$/,
      "Must contain digits followed by '.' Example 4.13.7. No support for versions ending with characters."
    )
    .when("removeCustomer", {
      is: false,
      then: Yup.string().required("PCS version is required."),
    }),
  customerName: Yup.string()
    .required("Customer name is required.")
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "Must contain only letters, numbers and dashes."
    ),
  smartSheetId: Yup.string()
    .required("Smartsheet Id is required.")
    .matches(
      /^(proser|ps)-\d{3,4}-[a-zA-Z]{4}|^-$/,
      "Must start with 'proser' or 'ps' followed by '-' and 3 or 4 digits. Then '-' and 4 letters. If no Smartsheet Id available then use dash '-'."
    ),
  emailRecipients: Yup.string()
    .required("Email Recipients field is required.")
    .matches(/^([\w+-.%]+@pricer\.com)(,\s*[\w+-.%]+@pricer\.com)*$/, {
      message:
        "Please enter one or more email addresses separated by commas and with the @pricer.com domain",
    }),
  removeCustomer: Yup.boolean().optional(),
  jiraTicket: Yup.string()
    .matches(
      /^PD-[0-9]{3,4}$|^-$/,
      "Must start with PD- and contain 3 or 4 digits. If no Jira ticket # available then use dash '-'."
    )
    .optional(),
  forceDeletion: Yup.boolean().optional(),
});

export const customerInitialValue = {
  // create customer
  commonDatabase: false,
  customerName: "",
  customerProfile: "",
  clusterSelect: "",
  pcsVersion: "",
  smartSheetId: "",
  emailRecipients: "",
  removeCustomer: false,
  // remove customer
  jiraTicket: "-",
  forceDeletion: false,
};
