import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLink,
  faPowerOff,
  faUser,
  faTrashAlt,
  faCheck,
  faTimes,
  faSort,
  faSortUp,
  faSortDown,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faTrashAlt);
  library.add(faCheck);
  library.add(faTimes);
  library.add(faSortUp);
  library.add(faSortDown);
  library.add(faSort);
  library.add(faSearch);
}

export default initFontAwesome;
