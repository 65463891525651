import { FormControl, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export function SelectDateCol({ formik, classes, disabled=false }) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required
      error={formik.touched.dateInput && Boolean(formik.errors.dateInput)}
      className={classes.formControlSelect}
    >
      <InputLabel shrink className={classes.customInputLabel}>
        {formik.touched.dateInput && formik.errors.dateInput
          ? formik.errors.dateInput
          : "Date"}
      </InputLabel>
      <DatePicker
        id="dateInput"
        name="dateInput"
        disablePast
        disableTouchListener
        format="YYYY-MM-DD"
        onChange={(value) => formik.setFieldValue("dateInput", value)}
        value={formik.values.dateInput}
        slotProps={{
          textField: {
            size: "small",
            variant: "outlined",
          },
        }}
        disabled={disabled}
      />
    </FormControl>
  );
}
