import React from "react";
import { DeleteForever } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

function DeleteButton({ item, mutation }) {
  return (
    <LoadingButton
      loading={mutation.isLoading}
      loadingPosition="start"
      startIcon={<DeleteForever color="error" />}
      variant="text"
      onClick={() => mutation.mutate(item)}
    />
  );
}

export default DeleteButton;
