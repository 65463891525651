export const calculateTotal = (table, fieldName) => {
  /**
   * Calculates the total for the given field name in the table
   * @param {object} table - The MaterialReactTable instance
   * @param {string} fieldName - The name of the field to calculate the total for
   * @returns {number} The total for the given field name
   */
  const filteredData = table.getFilteredRowModel();
  const total = filteredData.flatRows.reduce((acc, curr) => {
    const value = curr.original[fieldName];
    const parsedValue = typeof value === "string" ? parseInt(value) : value;
    if (!isNaN(parsedValue)) {
      return acc + parsedValue;
    }
    return acc;
  }, 0);
  return total;
};