import React, { useContext } from "react";

import { Button, Container, FormControlLabel, Divider } from "@mui/material";
import GreenSwitch from "../Form/GreenSwitch";
import TextInput from "../Form/TextInput";
import PCSUpgradeTypeSelect from "../Form/PCSUpgradeTypeSelect";
import moment from "moment/moment";

import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { upgradeSchema, upgradeInitialValues } from "../../validations/Upgrade";

import { usePostUpgradeJob } from "../../utils/crudFetch";
import { SelectClusterCol } from "../Form/SelectClusterCol";
import { SelectDateCol } from "../Form/SelectDateCol";
import { SelectTimeCol } from "../Form/SelectTimeCol";
import { SelectCustomerCol } from "../Form/SelectCustomerCol";
import { SelectPCSReleaseCol } from "../Form/SelectPCSReleaseCol";
import { ConfigContext } from "../../ContextProvider";

import { useStyles } from "../../themes/theme";

const UpgradeForm = ({ clusters }) => {
  const [configState] = useContext(ConfigContext);

  const classes = useStyles();
  const history = useHistory();
  const { mutateAsync } = usePostUpgradeJob(null, configState.AUTH0_TOKEN);

  const postJobs = async (data) => {
    await mutateAsync(
      { data },
      {
        onSuccess: () => {
          formik.setStatus({
            sent: true,
            msg: "Upgrade job created, redirecting ...",
          });
        },
        onError: (err) => {
          // display error message
          formik.setStatus({
            sent: false,
            msg: err.message + " " + err.request.statusText,
          });
        },
      }
    );
  };
  const formik = useFormik({
    validateOnChange: true,
    initialValues: upgradeInitialValues,
    onSubmit: async (values) => {
      // inputs as backend wants it
      let customer_list = [];

      if (values.toggleAll) {
        customer_list = ["ALL"];
      } else {
        customer_list = values.selectedCustomers;
      }

      const postBody = {
        tg_skip_basestation_check: values.bsCheck,
        upgrade_special_customer: values.upgradeSpecialCustomer,
        tg_pcs_version_upgrade_type: values.upgradeType,
        database_backup: values.databaseBackup,
        jira_ticket: values.jiraTicket,
        pcs_version: values.pcsReleaseSelect,
        cluster: values.clusterSelect,
        customer_list: customer_list,
        banner: values.setNotificationBanner,
        date:
          moment(values.dateInput).format("YYYYMMDD") +
          moment(values.timeInput, "HH:mm").format("HHmm"),
      };

      await postJobs(postBody);
      setTimeout(() => {
        // redirect to get-jobs page in 1000 ms (1 second)
        history.replace("/get-jobs");
      }, 1000);

      formik.setSubmitting(false);
    },

    validationSchema: upgradeSchema,
  });

  return (
    <Container className={classes.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mb: 2,
          }}
        >
          <Grid item style={{ paddingLeft: "10px" }}>
            <h2 className={classes.heading}>Upgrade</h2>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <GreenSwitch
                  name="bsCheck"
                  onChange={formik.handleChange}
                  checked={formik.values.bsCheck}
                />
              }
              label="Skip BS Check"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <GreenSwitch
                  name="upgradeSpecialCustomer"
                  onChange={formik.handleChange}
                  checked={formik.values.upgradeSpecialCustomer}
                />
              }
              label="Upgrade special customer"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <GreenSwitch
                  name="databaseBackup"
                  onChange={formik.handleChange}
                  checked={formik.values.databaseBackup}
                />
              }
              label="Database Backup"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <GreenSwitch
                  name="setNotificationBanner"
                  onChange={formik.handleChange}
                  checked={formik.values.setNotificationBanner}
                />
              }
              label="Set notification banner"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row">
          <Grid item xs={4}>
            <SelectClusterCol
              formik={formik}
              customerList={clusters}
              classes={classes}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectCustomerCol
              formik={formik}
              customerList={clusters}
              classes={classes}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectDateCol formik={formik} classes={classes} />
          </Grid>
          <Grid item xs={4}>
            <SelectTimeCol formik={formik} classes={classes} />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              formik={formik}
              classes={classes}
              label="jiraTicket"
              helperText="Jira Ticket"
              validate={formik.validateField}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectPCSReleaseCol
              formik={formik}
              classes={classes}
              configState={configState}
            />
          </Grid>
          <Grid item xs={4}>
            <PCSUpgradeTypeSelect formik={formik} classes={classes} />
          </Grid>
        </Grid>
        <Divider style={{ margin: "16px 0", backgroundColor: "#d9d9d9" }} />

        <Grid container alignItems="flex-end" justifyContent="end">
          <Grid item>{/* empty item to fill the left side */}</Grid>
          <Grid item>
            <Button
              id="submit-button"
              type="submit"
              variant="contained"
              color={!formik.isValid || !formik.dirty ? "primary" : "success"}
              className={classes.submitButton}
              style={{ textTransform: "none" }}
              disabled={!formik.isValid || !formik.dirty ? true : false}
            >
              {formik.isSubmitting ? "Scheduling…" : "Schedule"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
export default UpgradeForm;
