import React, { useContext } from "react";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  Divider,
  Grid,
} from "@mui/material";
import TextInput from "../Form/TextInput";
import { useFormik } from "formik";
import {
  customerSchema,
  customerInitialValue,
} from "../../validations/Customer";
import { SelectClusterCol } from "../Form/SelectClusterCol";
import { SelectCustomerCol } from "../Form/SelectCustomerCol";
import { SelectPCSReleaseCol } from "../Form/SelectPCSReleaseCol";
import { usePostCustomer, useDeleteCustomer } from "../../utils/crudFetch";

import { useStyles } from "../../themes/theme";
import GreenSwitch from "../Form/GreenSwitch";
import { ConfigContext } from "../../ContextProvider";

export const CustomerForm = ({ clusters }) => {
  const [configState] = useContext(ConfigContext);

  const classes = useStyles();
  const { mutateAsync: createMutateAsync } = usePostCustomer(null, configState.AUTH0_TOKEN);
  const { mutateAsync: deleteMutateAsync } = useDeleteCustomer(null, configState.AUTH0_TOKEN);

  const postCustomer = async (data) => {

    await createMutateAsync(
      { data },
      {
        onSuccess: () => {
          formik.setStatus({
            sent: true,
            msg: "Customer setup initiated...",
          });
        },
        onError: (err) => {
          // display error message
          formik.setStatus({
            sent: false,
            msg: err.message + " " + err.request.statusText,
          });
        },
      }
    );
  };

  const deleteCustomer = async (data) => {
    await deleteMutateAsync(
      { data },
      {
        onSuccess: () => {
          formik.setStatus({
            sent: true,
            msg: "Customer remove initiated...",
          });
        },
        onError: (err) => {
          // display error message
          formik.setStatus({
            sent: false,
            msg: err.message + " " + err.request.statusText,
          });
        },
      }
    );
  };

  const formik = useFormik({
    initialValues: customerInitialValue,
    onSubmit: async (values) => {
      let postBody = {};
      // if we are removing a customer
      if (values.removeCustomer) {
        postBody = {
          type: "customer",
          customer_name: values.customerName,
          cluster_dir: values.clusterSelect,
          smartsheet_id: values.smartSheetId,
          jira_ticket: values.jiraTicket, // required false
          force_deletion: values.forceDeletion, // false default
          email_recipients: values.emailRecipients,
        };
        // we are removing a customer
        await deleteCustomer(postBody);
      } else {
        postBody = {
          type: "customer",
          common_database: values.commonDatabase,
          customer_name: values.customerName,
          customer_profile: values.customerProfile,
          cluster_dir: values.clusterSelect,
          pcs_version: values.pcsReleaseSelect,
          smartsheet_id: values.smartSheetId,
          jira_ticket: values.jiraTicket, // required false
          email_recipients: values.emailRecipients,
        };
        await postCustomer(postBody);
      }
      setTimeout(() => {
        formik.resetForm();
        formik.setSubmitting(false);
      }, 1000);
      // dirty hack to refresh the page
      window.location.reload();
    },

    validationSchema: customerSchema,
  });

  return (
    <Container className={classes.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mb: 2,
          }}
        >
          <Grid item style={{ paddingLeft: "10px" }}>
            <h2 className={classes.heading}>Customer</h2>
          </Grid>
          <Grid item xs={true} />

          <Grid item>
            <FormControlLabel
              control={
                <GreenSwitch
                  name="removeCustomer"
                  onChange={formik.handleChange}
                  checked={formik.values.removeCustomer}
                />
              }
              label="Remove Customer"
              labelPlacement="start"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <GreenSwitch
                  name="commonDatabase"
                  onChange={formik.handleChange}
                  checked={formik.values.commonDatabase}
                  disabled={formik.values.removeCustomer}
                />
              }
              label="Common Database"
              labelPlacement="start"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <GreenSwitch
                  name="forceDeletion"
                  onChange={formik.handleChange}
                  checked={formik.values.forceDeletion}
                />
              }
              label="Force Deletion"
              labelPlacement="start"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} direction="row">
          <Grid item xs={4}>
            <SelectClusterCol
              formik={formik}
              customerList={clusters}
              classes={classes}
            />
          </Grid>
          <Grid item xs={4}>
            {formik.values.removeCustomer ? (
              <SelectCustomerCol
                formik={formik}
                customerList={clusters}
                classes={classes}
                disabled={!formik.values.removeCustomer}
              />
            ) : (
              <TextInput
                formik={formik}
                classes={classes}
                label="customerName"
                helperText="Customer Name"
                validate={formik.validateField}
                invalid={formik.values.customerName ? true : false}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <TextInput
              formik={formik}
              classes={classes}
              label="smartSheetId"
              helperText="Smartsheet Id"
              validate={formik.validateField}
              invalid={formik.values.smartSheetId ? true : false}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              variant="outlined"
              required
              className={classes.formControl}
            >
              <InputLabel
                shrink
                className={
                  formik.values.removeCustomer
                    ? `${classes.customInputLabel} ${classes.customInputLabelDisabled}`
                    : classes.customInputLabel
                }
              >
                Customer Profile
              </InputLabel>
              <Select
                labelId="customerProfile-select-label"
                id="customerProfile"
                name="customerProfile"
                size="small"
                value={formik.values.customerProfile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                disabled={
                  formik.values.removeCustomer ||
                  formik.values.clusterSelect === ""
                    ? true
                    : false
                }
              >
                {formik.values.clusterSelect.includes("pcs-trial") && (
                  <MenuItem value="trial">Trial</MenuItem>
                )}
                {formik.values.clusterSelect.includes("non-prod") && (
                  <MenuItem value="test">Test</MenuItem>
                )}
                {formik.values.clusterSelect.includes("prod") && [
                  <MenuItem key="prod" value="prod">
                    Prod
                  </MenuItem>,
                  <MenuItem key="preprod" value="preprod">
                    Preprod
                  </MenuItem>,
                ]}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              formik={formik}
              classes={classes}
              label="jiraTicket"
              helperText="Jira Ticket"
              validate={formik.validateField}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectPCSReleaseCol
              formik={formik}
              classes={classes}
              configState={configState}
              disabled={formik.values.removeCustomer}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            formik={formik}
            classes={classes}
            label="emailRecipients"
            helperText="Email Recipients"
            validate={formik.validateField}
            multiline={true}
            rows={4}
          />
        </Grid>
        <Divider style={{ margin: "16px 0", backgroundColor: "#d9d9d9" }} />

        <Grid container alignItems="flex-end" justifyContent="end">
          <Grid item>{/* empty item to fill the left side */}</Grid>
          <Grid item>
            <Button
              id="submit-button"
              type="submit"
              variant="contained"
              color={!formik.isValid || !formik.dirty ? "primary" : "success"}
              className={classes.submitButton}
              style={{ textTransform: "none" }}
              disabled={!formik.isValid || !formik.dirty ? true : false}
            >
              {formik.isSubmitting ? "Scheduling…" : "Schedule"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CustomerForm;
