import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AddPCSReleaseButton = ({ handleSubmit }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        color="success"
        size="small"
        variant="contained"
        onClick={handleClickOpen}
      >
        Add PCS Release
      </Button>
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const pcs_release = formJson.pcs_release;
                console.log(pcs_release);
                handleSubmit(formJson);
                handleClose();
            },
        }}
    >
        <DialogTitle>Add PCS Release</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new PCS Release, 
            please enter the version number below. e.g. 4.30.1
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="pcs_release"
            name="pcs_release"
            label="PCS Release"
            type="text"
            fullWidth
            variant="standard"
            color="success"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Cancel</Button>
          <Button type="submit" color="success">Create</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}