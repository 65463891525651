export const BannersColumns = () => [
  {
    header: "Customer",
    accessorKey: "customer",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Title",
    accessorKey: "title",
  },
  {
    header: "Message",
    accessorKey: "message",
  }
];
