import Papa from "papaparse";
const flattenObject = (ob) => {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === "object" && ob[i] !== null && !Array.isArray(ob[i])) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else if (Array.isArray(ob[i])) {
      const flatArray = ob[i].map((item) => {
        if (typeof item === "object" && item !== null) {
          return JSON.stringify(flattenObject(item));
        } else {
          return item;
        }
      });
      toReturn[i] = flatArray.join(",");
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
export const exportData = (content, fileName, format = "json") => {
  let mimeType, fileExtension, data;
  if (format === "json") {
    mimeType = "application/json;charset=utf-8;";
    fileExtension = "json";
    data = JSON.stringify(content, null, 2);
  } else if (format === "csv") {
    mimeType = "text/csv;charset=utf-8;";
    fileExtension = "csv";
    const flattenedData = content.map(flattenObject);

    const keyLengths = flattenedData.map(row => Object.keys(row).length)
    const maxKeysLength = Math.max(...keyLengths)
    const maxKeysIndex = keyLengths.indexOf(maxKeysLength)
    const headers = Object.keys(flattenedData[maxKeysIndex]);

    // Create 2D array
    const rows = [];
    flattenedData.forEach((item) => {
      const row = [];
      for (const key of headers) {
        const value = item[key];
        if (Array.isArray(value)) {
          // Join array values with comma
          row.push(value.join(","));
        } else {
          row.push(value);
        }
      }
      rows.push(row);
    });

    // Convert 2D array to CSV
    const csvData = Papa.unparse({ fields: headers, data: rows });
    data = csvData;
  } else {
    throw new Error(`Unsupported format: ${format}`);
  }

  const blob = new Blob([data], { type: mimeType });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName}.${fileExtension}`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
