import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  createFilterOptions,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {getCustomersByCluster, getCustomersByApp} from "../../utils/getCustomersByCluster";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function SelectCustomerCol({
  customerList,
  appList,
  formik,
  classes,
  disabled = false,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  let selectedCustomerByCluster = React.useMemo(
    () =>
      getCustomersByCluster(customerList || [], formik.values.clusterSelect),
    [customerList, formik.values.clusterSelect]
  );

  let selectCustomersByApp = React.useMemo(
      () =>
          getCustomersByApp(appList || [], formik.values.appSelect),
      [appList, formik.values.appSelect]
  );

  let selectCustomers = [];

  if (selectCustomersByApp.length > 0) {
    if (selectedCustomerByCluster.length > 0) {
      formik.values.clusterSelect = "";
      setSelectedOptions([]);
    }
    formik.values.bannerApp = "dataload";
    selectCustomers = selectCustomersByApp;
  }
  if (selectedCustomerByCluster.length > 0) {
    if (selectCustomersByApp.length > 0) {
      formik.values.appSelect = "";
      setSelectedOptions([]);
    }
    formik.values.bannerApp = "pcs";
    selectCustomers = selectedCustomerByCluster;
  }

  const inputProps = {
    variant: "outlined",
    error: Boolean(
      formik.touched.selectedCustomers && formik.errors.selectedCustomers
    ),
    disabled: disabled,
  };

  const { formControlSelect, customInputLabel } = classes;
  const allSelected =
    selectCustomers.length === selectedOptions.length;

  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);

  const handleClearOptions = () => {
    setSelectedOptions([]);
    formik.setFieldTouched("selectedCustomers", false);
    formik.setFieldValue("selectedCustomers", []);
  };

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      const allValues = selectCustomers.map((option) => option);

      setSelectedOptions(selectCustomers);
      formik.setFieldTouched("selectedCustomers", true);
      formik.setFieldValue("selectedCustomers", allValues);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll(!allSelected);
  };

  const getOptionLabel = (option) => option;

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option === "select-all")) {
        handleToggleSelectAll();
        formik.setFieldValue("selectedCustomers", selectCustomers);
      } else {
        handleToggleOption(selectedOptions);
        formik.setFieldValue("selectedCustomers", selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions();
    }
  };

  const renderOption = (props, option, { selected }) => {
    const selectAllProps =
      option === "select-all" ? { checked: allSelected } : {};
    return (
      <li key={option} {...props}>
        {option === "select-all" ? (
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            onChange={handleToggleSelectAll}
            {...selectAllProps}
          />
        ) : (
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
        )}
        {option}
      </li>
    );
  };

  const filter = createFilterOptions();

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const selectAllOption = "select-all";
    return [selectAllOption, ...filtered];
  };

  return (
    <FormControl
      fullWidth
      variant={inputProps.variant}
      required={formik.values.clusterSelect === "" ? true : inputProps.disabled}
      error={inputProps.error}
      className={formControlSelect}
      disabled={formik.values.clusterSelect === "" ? true : inputProps.disabled}
    >
      <InputLabel shrink className={customInputLabel}>
        {formik.touched.selectedCustomers && formik.errors.selectedCustomers
          ? formik.errors.selectedCustomers
          : "Select Customers"}
      </InputLabel>
      <Autocomplete
        multiple
        size="small"
        id="selectedCustomers"
        name="selectedCustomers"
        options={selectCustomers}
        value={selectedOptions}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => option === value}
        filterOptions={filterOptions}
        onChange={handleChange}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            InputProps={{
              ...params.InputProps,
              endAdornment: params.InputProps.endAdornment,
              inputProps: {
                ...params.inputProps,
                children: null,
              },
            }}
          />
        )}
        // disabled={
        //   formik.values.clusterSelect === "" ? true : inputProps.disabled
        // }
      />
    </FormControl>
  );
}
export default SelectCustomerCol;
