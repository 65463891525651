import { useGetJobs } from "../utils/crudFetch";
import React, { useMemo, useContext } from "react";
import MaterialReactTable, {
  MRT_GlobalFilterTextField,
  MRT_ToolbarInternalButtons,
} from "material-react-table";

import {
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  Container,
} from "@mui/material";
import { JobsColumns } from "../components/Jobs/JobsColumns";
import { useQueryClient } from "react-query";
import CustomerDetailPanel from "../components/Jobs/CustomerDetailPanel";
import DeleteButton from "../components/DeleteButton";
import { useDeleteJob } from "../utils/crudFetch";
import { ConfigContext } from "../ContextProvider";

export const GetJobs = () => {
  const [configState] = useContext(ConfigContext);
  const {
    data: jobs,
    isError,
    isLoading,
  } = useGetJobs(null, configState.AUTH0_TOKEN);

  const theme = useTheme();
  const queryClient = useQueryClient();
  const deleteMutation = useDeleteJob(queryClient, configState.AUTH0_TOKEN);

  const columns = useMemo(() => JobsColumns(), []);

  function formatCluster(cluster) {
    const clusterObj = {
      env: cluster.split("/")[0],
      region: cluster.split("/")[1],
      clusterName: cluster.split("/")[2],
    };
    return clusterObj;
  }

  const formattedData = useMemo(() => {
    return typeof jobs?.data === "object" && Object.keys(jobs?.data)?.length
      ? Object.values(jobs.data).map((job) => {
          const clusterObj = formatCluster(job.cluster);
          return {
            ...job,
            environment: clusterObj.env,
            region: clusterObj.region,
            clusterName: clusterObj.clusterName,
          };
        })
      : [];
  }, [jobs?.data]);

  // Determine the height of the spacer based on the screen size and the `size` property of the `Tabs` component
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const tabsHeight = isSmallScreen ? 8 : 18;
  const tableWidth = isSmallScreen ? "md" : "lg";
  return (
    <Box>
      <Container maxWidth={tableWidth}>
        <Box sx={{ height: tabsHeight }} />

        <MaterialReactTable
          usePaperWrapper={false}
          enableStickyHeader
          muiExpandButtonProps={({ row }) => ({
            disabled: row.original.customer_list.length === 1,
          })}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "1px",
              backgroundColor: theme.palette.background.main,
            },
          }}
          muiTableContainerProps={{
            sx: {
              backgroundColor: "#fff",
              borderRadius: "1px",
              border: `1px solid ${theme.palette.divider}`,
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              borderRadius: "1px",
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: "#fff",
            },
          }}
          muiTableHeadProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          muiTableHeadRowProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          columns={columns}
          data={
            jobs?.data.length === 0 || jobs?.data.entries === undefined
              ? []
              : formattedData
          }
          defaultColumn={{
            maxSize: 200,
            minSize: 120,
          }}
          positionGlobalFilter="left"
          enableDensityToggle={false}
          showToolbarDropZone={false}
          initialState={{
            density: "compact",
            pagination: { pageSize: 15 },
            columnVisibility: {
              environment: false,
              region: false,
            },
          }}
          renderDetailPanel={CustomerDetailPanel}
          renderTopToolbar={({ table }) => (
            <React.Fragment>
              <Box display="flex" justifyContent="space-between" sx={{ py: 2 }}>
                <Box display="flex" justifyContent="flex-start">
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ToolbarInternalButtons table={table} />
                </Box>
              </Box>
              <Divider />
            </React.Fragment>
          )}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Remove Job",
            },
          }}
          enableRowActions={true}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <Box>
              <DeleteButton item={row.original} mutation={deleteMutation} />
            </Box>
          )}
          state={{ isLoading }}
          showAlertBanner={isError}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: "error loading data",
                }
              : undefined
          }
        />
      </Container>
    </Box>
  );
};

export default GetJobs;
