import { Chip, Box } from "@mui/material";
const CustomerDetailPanel = ({ row }) => {
  const customers = row.original.customer_list.slice(1);

  if (customers.length === 0) {
    return null;
  }

  return (
    <Box p={2}>
      {customers.map((customer, index) => (
        <Chip key={index} label={customer} color="primary" />
      ))}
    </Box>
  );
};

export default CustomerDetailPanel;
