import React from "react";
import { Switch } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const GreenSwitch = (props) => {
  const { checked, ...rest } = props;
  return (
    <Switch
      checked={checked}
      {...rest}
      checkedIcon={<CheckCircleIcon sx={{ color: "#fff" }} />}
      color="success"
      sx={{
        "& .MuiSwitch-thumb": {
          backgroundColor: "#ffffff",
          opacity: 1,
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: "success.main",
          opacity: 0.8,
        },
      }}
    />
  );
};

export default GreenSwitch;
