import React, { useEffect, useContext, useState, useMemo } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, CssBaseline, Divider, ThemeProvider } from "@mui/material";
import NavBar from "./components/Layout/NavBar";
import SubBar from "./components/Layout/SubBar";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import GetJobs from "./pages/GetJobs";

import CustomerInformation from "./pages/CustomerInformation";
import history from "./utils/history";
import { ConfigContext } from "./ContextProvider";
import { appTheme } from "./themes/theme";
import initFontAwesome from "./utils/initFontAwesome";
import Loading from "./components/Loading";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Unauthorized from "./components/Unauthorized";
import GetBanners from "./pages/GetBanners";
import { GetPCSReleases } from "./pages/GetPCSRelease";

initFontAwesome();

const App = ({ config }) => {
  const { isLoading, error, getAccessTokenSilently, user } = useAuth0();
  const [, setConfigState] = useContext(ConfigContext);
  const [isAllowed, setIsAllowed] = useState(true);
  const [isLoadingAllowed, setIsLoadingAllowed] = useState(true);
  const allowedPaths = useMemo(() => ["/customer-information", "/profile"], []);

  useEffect(() => {
    setConfigState(config);
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setConfigState({ ...config, AUTH0_TOKEN: token });
      } catch (e) {
        console.log(e.message);
      }
    };
    getToken();

    if (
      config.ALLOWED_GROUPS &&
      user &&
      !config.ALLOWED_GROUPS.some((group) =>
        user["https://pricer.com/groups"].includes(
          String(group).toLocaleLowerCase()
        )
      ) &&
      !allowedPaths.includes(window.location.pathname)
    ) {
      setIsAllowed(false);
    }
    setIsLoadingAllowed(false);
  }, [allowedPaths, config, getAccessTokenSilently, setConfigState, user]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading || isLoadingAllowed) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box bgcolor={"background.main"} minHeight="100vh">
            <NavBar config={config} />
            <Divider />
            <SubBar config={config} />
            <Divider />

            <Switch>
              <Route path="/unauthorized" component={Unauthorized} />
              <Route
                path="/customer-information"
                component={CustomerInformation}
              />
              <Route path="/profile" component={Profile} />
              {isAllowed && (
                <>
                  <Route exact path="/" component={Home} />
                  <Route path="/get-jobs" component={GetJobs} />
                  <Route path="/get-banners" component={GetBanners} />
                  <Route path="/get-pcs-releases" component={GetPCSReleases} />
                </>
              )}
              <Route
                exact
                path="/"
                render={() =>
                  !isAllowed ? <Redirect to="/customer-information" /> : null
                }
              />
              <Route
                path="*"
                render={({ location }) => {
                  if (isAllowed) {
                    return <Redirect to="/" />;
                  } else {
                    if (allowedPaths.includes(location.pathname)) {
                      return null;
                    } else {
                      return <Redirect to="/customer-information" />;
                    }
                  }
                }}
              />
            </Switch>
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  );
};
export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />,
});
