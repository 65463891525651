import React, { useState, useContext } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import UpgradeForm from "../Upgrade/UpgradeForm";
import CustomerForm from "../Customer/CustomerForm";
import BannerForm from "../Banner/BannerForm";

import TabPanel from "./TabPanel";
import { a11yProps } from "../../utils/a11yProps";
import { useGetCustomers } from "../../utils/crudFetch";
import { useTheme } from "@mui/material/styles";
import { ConfigContext } from "../../ContextProvider";

function NavTab() {
  const [configState] = useContext(ConfigContext);

  const theme = useTheme();

  const [value, setValue] = useState(0);
  const { data: customers } = useGetCustomers(null, configState.AUTH0_TOKEN);
  const clusters = customers?.data.filter(c => c.cluster !== undefined)
  const apps = customers?.data.filter(c => c.app !== undefined);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabs = [
    { label: "Upgrade", component: <UpgradeForm clusters={clusters} /> },
    { label: "Banner", component: <BannerForm clusters={clusters} apps={apps} /> },
    { label: "Customer", component: <CustomerForm clusters={clusters} /> },
  ];

  return (
    <>
      <Box sx={{ marginBottom: "24px", marginTop: "16px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Tabs"
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.success.main,
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                disableRipple
                disableFocusRipple
                disableTouchRipple
                label={tab.label}
                {...a11yProps(index)}
                key={index}
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    color: "black",
                  },
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  );
}

export default NavTab;
