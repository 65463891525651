import axios from "axios";
import { useContext } from "react";
import { ConfigContext } from "../ContextProvider";

const useHttp = () => {
  const [configState] = useContext(ConfigContext);
  const baseUrl = configState.REACT_APP_BACKEND_URL;

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${configState.AUTH0_TOKEN}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
  return api;
};

export const useGetHttp = () => {
  const api = useHttp();
  const get = async (endpoint, config) => {
    const res = await api.get(endpoint, config);
  //  console.log("response from get", res);
    return res;
  };
  return get;
};

export const usePostHttp = () => {
  const api = useHttp();
  const post = async (endpoint, data, config={}) => {
    const res = await api.post(endpoint, data, config);
//    console.log("response from post", res);
    return res;
  };
  return post;
};

export const useDeleteHttp = () => {
  const api = useHttp();
  const deleteMethod = async (endpoint, config) => {
    const res = await api.delete(endpoint, config);
//    console.log("response from delete", res);
    return res;
  };
  return deleteMethod;
};
