import React, { useState } from "react";
import { Avatar, Typography, Menu, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowDropDown, ExitToApp, Person } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const NavBar = ({ config }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logout } = useAuth0();
  const { name } = user || {};
  const groups = user ? user["https://pricer.com/groups"] : undefined;
  const groupName = groups?.find((group) =>
    config.ALLOWED_GROUPS.includes(String(group).toLocaleLowerCase())
  );

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logoutWithRedirect();
    handleMenuClose();
  };

  const handleProfileClick = () => {
    handleMenuClose();
    history.push("/profile");
  };
  return (
    <Box bgcolor={"white"}>
      <Box
        display="flex"
        sx={{
          paddingBottom: 2,
          paddingInline: 4,
          backgroundColor: "#ffffff",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Avatar
          sx={{
            bgcolor: "secondary.main",
            fontSize: 20,
            lineHeight: "20px",
            fontWeight: 600,
            borderRadius: 1,
            top: 12,
            color: "#E3ECF4",
          }}
          variant="square"
        >
          Pc
        </Avatar>
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 14,
            marginTop: 2,
            marginLeft: 2,
            letterSpacing: "0,1",
            lineHeight: "20px",
          }}
          component="div"
        >
          Plaza Classic Console
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 9,
              letterSpacing: "0,1",
            }}
            component="div"
          >
            by Cloud-Infra
          </Typography>
        </Typography>
        <Typography
          sx={{
            justifyContent: "flex-end",
            marginLeft: "auto",
            marginTop: 2,
            marginRight: 2,
            fontSize: 14,
            fontWeight: 600,
            display: "flex",
            flexDirection: "column",
          }}
          component="div"
        >
          {name ? name : "Unauthorized"}
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 9,
              letterSpacing: "0,1",
            }}
            component="div"
          >
            {groupName !== undefined
              ? groupName + " (group)"
              : "Unauthorized (no-group)"}
          </Typography>
        </Typography>
        <ArrowDropDown
          onClick={handleMenuOpen}
          sx={{ marginRight: 1 }}
        ></ArrowDropDown>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
        >
          <MenuItem onClick={handleProfileClick}>
            <Person sx={{ marginRight: 1 }} />
            Profile
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ExitToApp sx={{ marginRight: 1 }} />
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default NavBar;
