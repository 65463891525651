import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { ConfigProvider } from "./ContextProvider";
import { QueryClient, QueryClientProvider } from "react-query";
//import { ReactQueryDevtools } from "react-query/devtools";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
fetch("/config/config.json")
  .then((r) => r.json())
  .then((config) => {
    ReactDOM.render(
      <Auth0Provider
        audience={
          config.REACT_APP_AUTH0_AUDIENCE
            ? config.REACT_APP_AUTH0_AUDIENCE
            : null
        }
        domain={config.REACT_APP_AUTH0_DOMAIN}
        clientId={config.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        cacheLocation={"localstorage"}
        onRedirectCallback={onRedirectCallback}
      >
        <ConfigProvider>
          <QueryClientProvider client={new QueryClient()}>
            <App config={config} />
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </ConfigProvider>
      </Auth0Provider>,
      document.getElementById("root")
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
