import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function PCSUpgradeTypeSelect({ formik, classes }) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required
      className={classes.formControl}
    >
      <InputLabel shrink className={classes.customInputLabel}>
        PCS Upgrade Type
      </InputLabel>
      <Select
        labelId="pcs-upgrade-type-select-label"
        id="pcs-upgrade-type-select"
        name="upgradeType"
        size="small"
        value={formik.values.upgradeType}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        <MenuItem value="standard">Standard</MenuItem>
        <MenuItem value="hotfix">Hotfix</MenuItem>
        <MenuItem value="skip_version_check">Skip version check</MenuItem>
      </Select>
    </FormControl>
  );
}

export default PCSUpgradeTypeSelect;
