import React, {
  useContext,
  memo,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from "react";
import MaterialReactTable from "material-react-table";
import { Box, useMediaQuery, Container, useTheme } from "@mui/material";
import { customerInformationColumns } from "../components/CustomerInformation/Columns";
import { useGetCustomerInformation } from "../utils/crudFetch";
import { exportData } from "../utils/exportData";
import { tableStyles } from "../themes/theme";
import { CustomTopToolbar } from "../components/CustomerInformation/CustomTopToolbar";
import { calculateTotal } from "../utils/calculateTotal";
import { initialState } from "../components/CustomerInformation/initialState";
import debounce from "lodash/debounce";
import { ConfigContext } from "../ContextProvider";

// Create a constant to store the debounce time
const DEBOUNCE_TIME = 350;

export const CustomerInformation = memo(() => {
  const [configState] = useContext(ConfigContext);

  const {
    data: customerData,
    isLoading,
    isError,
  } = useGetCustomerInformation(null, configState.AUTH0_TOKEN);

  // Use `useState` hook to store the data
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [totals, setTotals] = useState({
    number_of_stores: 0,
    number_of_esls: 0,
    number_of_items: 0,
    number_of_stores_xs: 0,
    number_of_stores_s: 0,
    number_of_stores_m: 0,
    number_of_stores_l: 0,
    number_of_stores_xl: 0,
    reference_stores: 0,
    number_of_stopped_stores: 0,
  });

  // Use `useMemo` to memoize the filtered data
  const filteredData = useMemo(
    () => data.filter((item) => item.customer !== "common"),
    [data]
  );

  // Use `useCallback` to memoize the `handleExportData` function
  const handleExportData = useCallback(
    (format) => {
      // Filter the data based on rowSelection if it's not empty
      const dataToExport =
      Object.keys(rowSelection).length === 0
        ? filteredData
        : filteredData.filter((_, index) => rowSelection[index]);
      if (format === "json") {
        const fileName = `${new Date().toISOString()}-exported_data.json`;
        exportData(dataToExport, fileName, "json");
      } else if (format === "csv") {
        const fileName = `${new Date().toISOString()}-exported_data.csv`;
        exportData(dataToExport, fileName, "csv");
      }
    },
    [filteredData, rowSelection]
  );

  const handleCalculateTotal = useCallback(
    (table) => {
      const keysToCalculate = [
        "number_of_stores",
        "number_of_esls",
        "number_of_items",
        "number_of_stores_xs",
        "number_of_stores_s",
        "number_of_stores_m",
        "number_of_stores_l",
        "number_of_stores_xl",
        "reference_stores",
        "number_of_stopped_stores",
      ];
      const debouncedCalculateTotal = debounce(() => {
        const newTotals = { ...totals };

        keysToCalculate.forEach((key) => {
          newTotals[key] = calculateTotal(table, key);
        });

        setTotals(newTotals);
      }, DEBOUNCE_TIME);

      debouncedCalculateTotal();
    },
    [totals]
  );

  const columns = useMemo(
    () =>
      customerInformationColumns({
        calculatedTotals: totals,
      }),
    [totals]
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const tabsHeight = isSmallScreen ? 8 : 18;
  const tableWidth = isSmallScreen ? "md" : "lg";

  // Use `useEffect` hook to update the data state
  useEffect(() => {
    if (!isLoading && !isError && customerData?.data) {
      setData(customerData.data);
    }
  }, [customerData, isLoading, isError]);

  return (
    <Box>
      <Container maxWidth={tableWidth}>
        <Box sx={{ height: tabsHeight }} />

        <MaterialReactTable
          {...tableStyles(theme)}
          usePaperWrapper={false}
          enableRowSelection
          onRowSelectionChange={setRowSelection}
          muiSelectCheckboxProps={{
            color: "success",
          }}
          enableStickyHeader
          muiSearchTextFieldProps={({ table }) => {
            handleCalculateTotal(table);
          }}
          columns={columns}
          data={filteredData}
          defaultColumn={{
            maxSize: 200,
            minSize: 120,
          }}
          positionGlobalFilter="left"
          enableDensityToggle={false}
          showToolbarDropZone={false}
          enablePinning
          initialState={initialState}
          renderTopToolbar={({ table }) => (
            <CustomTopToolbar
              table={table}
              handleExportData={handleExportData}
            />
          )}
          state={{ isLoading, rowSelection }}
          showAlertBanner={isError}
          filterFns={{
            customContains: (row, id, filterValue) => {
              if (row.getValue(id) === undefined || filterValue === undefined) {
                return false;
              }
              return row.getValue(id).toString().toLocaleLowerCase().trim()
                  .includes(filterValue.toString().toLocaleLowerCase().trim());
            }
          }}
          globalFilterFn="customContains"
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: "error loading data",
                }
              : undefined
          }
        />
      </Container>
    </Box>
  );
});
export default CustomerInformation;
