import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export function SelectAppCol({ appList, formik, classes }) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={
        formik.touched.appSelect && Boolean(formik.errors.appSelect)
      }
      className={classes.formControlSelect}
    >
      <InputLabel shrink className={classes.customInputLabel}>
        {formik.touched.appSelect && formik.errors.appSelect
          ? formik.errors.appSelect
          : "Select App"}
      </InputLabel>
      <Select
        labelId="app-select-label"
        id="appSelect"
        name="appSelect"
        size="small"
        value={formik.values.appSelect}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        <MenuItem value="" disabled>
          {appList && appList.length > 0
            ? "Select App"
            : "Loading..."}
        </MenuItem>
        {appList &&
          appList.length > 0 &&
          appList.map((item) => (
            <MenuItem key={item.app} value={item.app}>
              {item.app}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
