export const initialState = {
  density: "compact",
  pagination: { pageSize: 15 },
  columnPinning: { left: ['customer'] },
  columnVisibility: {
    environment: false,
    region: false,
    number_of_stores_linked_labels_ge_50: false,
    number_of_stores_xs: false,
    number_of_stores_s: false,
    number_of_stores_m: false,
    number_of_stores_l: false,
    number_of_stores_xl: false,
    reference_stores: false,
    number_of_stopped_stores: false,
    store_unit_version: false,
    central_manager_version: false,
    timestamp: false,
    "prometheus_values.pricer_backoffice_entries_total_1d": false,
    "prometheus_values.pricer_transmitted_price_pages_total_1d": false,
    "prometheus_values.pricer_transmitted_price_pages_total_30d": false,
    "prometheus_values.pricer_transmitted_info_pages_total_1d": false,
    "prometheus_values.pricer_transmitted_info_pages_total_30d": false,
    "prometheus_values.pricer_label_flash_led_on_counter_total_1d": false,
    "prometheus_values.pricer_label_flash_led_on_counter_total_30d": false,
    "customer_profile_values.use_common_db": false,
    "customer_profile_values.pcs_license_profile_type": false,
    "values.pcm_usecases": false,
    updates_labels_ratio: false,
  },
};
