import React from "react";
import NavTab from "../components/Layout/NavTab";
import { Box, Container } from "@mui/material";

const Home = () => {
  return (
    <Container>
      <Box>
        <NavTab />
      </Box>
    </Container>
  );
};

export default Home;
