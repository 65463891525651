import { Chip } from "@mui/material";

export const PCSReleasesColumns = () => [
  {
    header: "PCS Release Version",
    accessorFn: (row) => {
      let versionDiv = <div>{row.pcsReleaseVersion}</div>;
      if (row.default) {
        versionDiv = <div>{row.pcsReleaseVersion} <Chip label="Default" size="small" sx={{ml: 1, fontWeight: 'bold'}}/></div>;
      }
      return versionDiv;
    },
  }
];
