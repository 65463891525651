import { useGetHttp, usePostHttp, useDeleteHttp } from "../hooks/useHttp";

import { useMutation, useQuery } from "react-query";

export const useGetJobs = (param, authToken) => {
  const get = useGetHttp();
  return useQuery(
    ["jobs", authToken],
    () => {
      if (!authToken) {
        // If the authToken is not available, return a Promise that resolves with an empty object
        return Promise.resolve({});
      }
      return get("/api/private/jobs/", { params: param });
    },
    {
      keepPreviousData: true,
      enabled: !!authToken, // Run the query only when authToken is available
    }
  );
};

export const useGetBanners = (param, authToken) => {
  const get = useGetHttp();
  return useQuery(["banners", authToken], () => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    return get("/api/private/banners/", { params: param });
  });
};

export const useGetPCSReleases = (param, authToken) => {
  const get = useGetHttp();
  return useQuery(["pcsReleases", authToken], () => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    return get("/api/private/settings/pcs-releases", { params: param });
  });
};

export const usePostPCSReleases = (queryClient, params, authToken) => {
  const post = usePostHttp();
  return useMutation({
    mutationFn: async (params) => {
      if (!authToken) {
        // If the authToken is not available, return a Promise that resolves with an empty object
        return Promise.resolve({});
      }
      await post("/api/private/settings/pcs-releases", null, { params: params.data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("pcsReleases");
    }
  });
};

export const useDeletePCSRelease = (queryClient, authToken) => {
  const deleteMethod = useDeleteHttp();
  return useMutation({
    mutationFn: async (data) => {
      if (!authToken) {
        // If the authToken is not available, return a Promise that resolves with an empty object
        return Promise.resolve({});
      }
      await deleteMethod("/api/private/settings/pcs-releases", {
        params: data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("pcsReleases");
    },
  });
};

export const useGetPCSReleasesDefault = (param, authToken) => {
  const get = useGetHttp();
  return useQuery(["pcsReleasesDefault", authToken], () => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    return get("/api/private/settings/pcs-releases/default", { params: param });
  });
};

export const usePostPCSReleasesDefault = (queryClient, params, authToken) => {
  const post = usePostHttp();
  return useMutation({
    mutationFn: async (data) => {
      if (!authToken) {
        // If the authToken is not available, return a Promise that resolves with an empty object
        return Promise.resolve({});
      }
      await post("/api/private/settings/pcs-releases/default", null, { params: data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("pcsReleasesDefault");
    }
  });
};

export const usePostUpgradeJob = (param, authToken) => {
  const post = usePostHttp();
  return useMutation((params) => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    post("/api/private/jobs/upgrade", params.data, {});
  });
};

export const usePostScheduleBannerJob = (param, authToken) => {
  const post = usePostHttp();
  return useMutation((params) => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    post("/api/private/jobs/banner", params.data, {});
  });
};

export const usePostSetBanner = (param, authToken) => {
  const post = usePostHttp();
  return useMutation((params) => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    post("/api/private/banners/", params.data, {});
  });
};

export const usePostBatchDeleteBanner = (queryClient, param, authToken) => {
  const post = usePostHttp();
  return useMutation({
    mutationFn: async (params) => {
      if (!authToken) {
        // If the authToken is not available, return a Promise that resolves with an empty object
        return Promise.resolve({});
      }
      await post("/api/private/banners:batchDelete", params.data, {});
    },
    onSuccess: (response, variables) => {
      console.log("Response: ", response)
      console.log("Variables:", variables)
      queryClient.setQueryData("banners", (oldData) => {
        const updatedData = { ...oldData };
        delete updatedData.data[variables];
        return updatedData;
      });
    },
  });
};

export const useDeleteJob = (queryClient, authToken) => {
  const deleteMethod = useDeleteHttp();
  return useMutation({
    mutationFn: async (params) => {
      if (!authToken) {
        // If the authToken is not available, return a Promise that resolves with an empty object
        return Promise.resolve({});
      }
      await deleteMethod(`/api/private/jobs/?id=${params.id}`, params.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("jobs");
    },
  });
};

export const useDeleteBanner = (queryClient, authToken) => {
  const deleteMethod = useDeleteHttp();
  return useMutation({
    mutationFn: async ({customer, app}) => {
      if (!authToken) {
        // If the authToken is not available, return a Promise that resolves with an empty object
        return Promise.resolve({});
      }
      await deleteMethod(`/api/private/banners/${app}/${customer}`);
    },
    onSuccess: (response, variables) => {
      queryClient.setQueryData("banners", (oldData) => {
        const updatedData = { ...oldData };
        delete updatedData.data[variables];
        return updatedData;
      });
    },
  });
};

export const useGetCustomers = (param, authToken) => {
  const get = useGetHttp();
  return useQuery(["customers", authToken], () => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    return get("/api/private/customers/", { params: param });
  }, {
    cacheTime: 1000 * 60 * 60 * 1, // 1 hour
    keepPreviousData: true,
    staleTime: 1000 * 60 * 30 * 1, // 30 minutes
    enabled: !!authToken, // Run the query only when authToken is available
  });
};


export const usePostCustomer = (param, authToken) => {
  const post = usePostHttp();
  return useMutation((params) => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    post("/api/private/jobs/customers", params.data, {});
  });
};

export const useDeleteCustomer = (param, authToken) => {
  const deleteMethod = useDeleteHttp();
  return useMutation((params) => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    deleteMethod(`/api/private/jobs/customers`, param.data);
  });
};

export const useGetCustomerInformation = (param, authToken) => {
  const get = useGetHttp();
  return useQuery(["customerinformation"], () => {
    if (!authToken) {
      // If the authToken is not available, return a Promise that resolves with an empty object
      return Promise.resolve({});
    }
    return get("/api/private/customers/contents", { params: param });
  }, {
    cacheTime: 1000 * 60 * 60 * 1, // 1 hour
    keepPreviousData: true,
    staleTime: 1000 * 60 * 30 * 1, // 30 minutes
    enabled: !!authToken, // Run the query only when authToken is available
  });
};
