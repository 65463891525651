import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export function SelectClusterCol({ customerList, formik, classes }) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={
        formik.touched.clusterSelect && Boolean(formik.errors.clusterSelect)
      }
      className={classes.formControlSelect}
    >
      <InputLabel shrink className={classes.customInputLabel}>
        {formik.touched.clusterSelect && formik.errors.clusterSelect
          ? formik.errors.clusterSelect
          : "Select Clusters"}
      </InputLabel>
      <Select
        labelId="cluster-select-label"
        id="clusterSelect"
        name="clusterSelect"
        size="small"
        value={formik.values.clusterSelect}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
      >
        <MenuItem value="" disabled>
          {customerList && customerList.length > 0
            ? "Select Clusters"
            : "Loading..."}
        </MenuItem>
        {customerList &&
          customerList.length > 0 &&
          customerList.map((item) => (
            <MenuItem key={item.cluster} value={item.cluster}>
              {item.cluster}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
