import { useGetPCSReleases, useGetPCSReleasesDefault, usePostPCSReleases, useDeletePCSRelease, usePostPCSReleasesDefault } from "../utils/crudFetch";
import React, { useMemo, useContext } from "react";
import MaterialReactTable, {
  MRT_GlobalFilterTextField,
  MRT_ToolbarInternalButtons,
} from "material-react-table";

import {
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  Container,
} from "@mui/material";
import DeleteButton from "../components/DeleteButton";
import SetDefaultButton from "../components/PCSReleases/SetDefaultButton";
import { useQueryClient } from "react-query";
import { AddPCSReleaseButton } from "../components/PCSReleases/AddPCSReleaseButton";
import { ConfigContext } from "../ContextProvider";
import { PCSReleasesColumns } from "../components/PCSReleases/PCSReleasesColumns";

export const GetPCSReleases = () => {
  const [configState] = useContext(ConfigContext);

  const theme = useTheme();
  const queryClient = useQueryClient();

  const { mutateAsync: createMutateAsync } = usePostPCSReleases(queryClient, null, configState.AUTH0_TOKEN);
  const postPCSRelease = async (data) => {

    await createMutateAsync(
      { data },
      {
        onSuccess: () => {
          console.log("success");
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  const { data: pcsReleases, isError: isGetPCSReleasesError, isLoading: isGetPCSReleasesLoading } = useGetPCSReleases(null, configState.AUTH0_TOKEN);
  const { data: pcsReleasesDefault, isError: isGetPCSReleasesDefaultError, isLoading: isGetPCSReleasesDefaultLoading } = useGetPCSReleasesDefault(null, configState.AUTH0_TOKEN);
  const isDataFormatable = typeof pcsReleases === "object" && pcsReleases.data;
  const isLoading = isGetPCSReleasesLoading || isGetPCSReleasesDefaultLoading;
  const isError = isGetPCSReleasesError || isGetPCSReleasesDefaultError;

  const deleteMutation = useDeletePCSRelease(queryClient, configState.AUTH0_TOKEN);
  const setDefaultMutation = usePostPCSReleasesDefault(queryClient, null, configState.AUTH0_TOKEN);

  const columns = useMemo(() => PCSReleasesColumns(), []);

  // format list of psc_version to [pcsReleaseVersion: pcs_version, default: true/false] if recieved data or empty array
  const formattedData = !isDataFormatable ? [] :
    Object.values(pcsReleases.data).map((pcsReleaseVersion) => {

    return {
      pcsReleaseVersion: pcsReleaseVersion,
      default: typeof pcsReleasesDefault === "object" && pcsReleaseVersion === pcsReleasesDefault.data,
    };
  });

  // Determine the height of the spacer based on the screen size and the `size` property of the `Tabs` component
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const tabsHeight = isSmallScreen ? 8 : 18;
  const tableWidth = isSmallScreen ? "md" : "lg";
  return (
    <Box>
      <Container maxWidth={tableWidth}>
        <Box sx={{ height: tabsHeight }} />

        <MaterialReactTable
          usePaperWrapper={false}
          enableStickyHeader
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "1px",
              backgroundColor: theme.palette.background.main,
            },
          }}
          muiTableContainerProps={{
            sx: {
              backgroundColor: "#fff",
              borderRadius: "1px",
              border: `1px solid ${theme.palette.divider}`,
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              borderRadius: "1px",
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: "#fff",
            },
          }}
          muiTableHeadProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          muiTableHeadRowProps={{
            sx: {
              backgroundColor: "#fff",
            },
          }}
          columns={columns}
          data={formattedData}
          defaultColumn={{
            maxSize: 200,
            minSize: 120,
          }}
          positionGlobalFilter="left"
          enableDensityToggle={false}
          showToolbarDropZone={false}
          initialState={{
            density: "compact",
            pagination: { pageSize: 15 },
          }}
          renderTopToolbar={({ table }) => (
            <React.Fragment>
              <Box display="flex" justifyContent="space-between" sx={{ py: 2 }}>
                <Box display="flex" justifyContent="flex-start">
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ToolbarInternalButtons table={table} />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  flexGrow={1}
                  sx={{
                    mx: 2,
                  }}
                >
                  <AddPCSReleaseButton
                    handleSubmit={(version) => postPCSRelease(version)}
                  />
                </Box>
              </Box>
              <Divider />
            </React.Fragment>
          )}
          enableRowActions={true}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <Box>
              <DeleteButton
                item={{pcs_release: row.original.pcsReleaseVersion}}
                mutation={deleteMutation}
              />
              <SetDefaultButton
                item={{pcs_release: row.original.pcsReleaseVersion}}
                mutation={setDefaultMutation}
              />
            </Box>
          )}
          state={{ isLoading, showAlertBanner: isError }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: "error loading data",
                }
              : undefined
          }
          positionToolbarAlertBanner='bottom'
        />
      </Container>
    </Box>
  );
};

export default GetPCSReleases;
