import moment from "moment";

import { Chip, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export const JobsColumns = () => [
  {
    header: "Environment",
    accessorKey: "environment",
  },
  {
    header: "Region",
    accessorKey: "region",
  },
  {
    header: "Cluster Name",
    accessorKey: "clusterName",
  },
  {
    header: "Customer List",
    accessorKey: "customer_list",
    Cell: ({ row }) => {
      const firstCustomer = row.original.customer_list[0];
      const moreCustomers = row.original.customer_list.length > 1;

      return (
        <Box display="flex" alignItems="center">
          <Chip label={firstCustomer} color="primary" />
          {moreCustomers && <Chip label="..." sx={{ ml: 1 }} color="primary" />}
        </Box>
      );
    },
  },
  {
    header: "Jira Ticket",
    accessorKey: "jira_ticket",
    accessorFn: (row) => {
      if (row.jira_ticket === "-" || row.jira_ticket === "") {
        return "";
      } else {
        return (
          <Typography variant="body2">
            <a href={row.jira_ticket} target="_blank" rel="noreferrer">
              {row.jira_ticket}
            </a>
          </Typography>
        );
      }
    },
  },
  {
    header: "Start Time",
    accessorKey: "date",
    accessorFn: (row) => {
      const formattedDate = moment(row.date).format("dddd MMMM D YYYY, HH:mm");
      const humanizedDate = moment(row.date).fromNow();
      const daysDifference = Math.abs(moment().diff(moment(row.date), "days"));
      let color;
      if (daysDifference < 1) {
        color = "error.main";
      } else if (daysDifference > 1 && daysDifference < 5) {
        color = "warning.main";
      } else {
        color = "success.main";
      }

      return (
        <Box>
          <Typography variant="body1">{formattedDate}</Typography>
          <Typography variant="body2" sx={{ color }}>
            {humanizedDate}
          </Typography>
        </Box>
      );
    },
  },
  {
    header: "Status",
    accessorKey: "task_status",
    Cell: ({ row }) => (
      <Typography
        color={
          row.original.task_status.status === "running"
            ? "warning.main"
            : "success.main"
        }
      >
        {row.original.task_status.status}
      </Typography>
    ),
  },
  {
    header: "Set Banner",
    accessorKey: "banner",
    accessorFn: (row) => {
      if (row.banner === true) {
        return <CheckIcon color="success" />;
      } else {
        return "";
      }
    },
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Upgrade Version",
    accessorKey: "pcs_version",
  },
];
