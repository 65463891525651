import { InputLabel, FormControl, TextField } from "@mui/material";

export function SelectTimeCol({ formik, classes, disabled=false }) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required
      error={formik.touched.timeInput && Boolean(formik.errors.timeInput)}
      className={classes.formControlSelect}
    >
      <InputLabel shrink className={classes.customInputLabel}>
        {formik.touched.timeInput && formik.errors.timeInput
          ? formik.errors.timeInput
          : "Time"}
      </InputLabel>
      <TextField
        id="timeInput"
        fullWidth
        type="time"
        variant="outlined"
        size="small"
        onChange={formik.handleChange}
        disabled={disabled}
      />
    </FormControl>
  );
}
