import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { svSE } from "@mui/material/locale";

export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1636,
    },
  },
  palette: {
    secondary: {
      main: "#165FA3",
    },
    primary: {
      main: "#E1E9E3",
    },
    background: {
      main: "#F8F8F8",
      default: "#F8F8F8",
    },
    divider: "#CECFCE",
    success: {
      main: "#107C10",
    },
  },
  typography: {
    fontFamily: ["Raleway", "Roboto", "-apple-system"].join(","),
  },
  svSE,
});

export const formContainerStyles = (theme) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "4px",
  padding: "16px",
  backgroundColor: "#fff",
  paddingLeft: "24px",
  paddingRight: "24px",
});

export const tableStyles = (theme) => ({
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      borderRadius: "1px",
      backgroundColor: theme.palette.background.main,
    },
  },
  muiTableContainerProps: {
    sx: {
      backgroundColor: "#fff",
      borderRadius: "1px",
      border: `1px solid ${theme.palette.divider}`,
    },
  },
  muiTableBodyCellProps: {
    sx: {
      backgroundColor: "#fff",
    },
  },
  muiBottomToolbarProps: {
    sx: {
      borderRadius: "1px",
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: "#fff",
    },
  },
  muiTableHeadProps: {
    sx: {
      backgroundColor: "#fff",
    },
  },
  muiTableHeadRowProps: {
    sx: {
      backgroundColor: "#fff",
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  formContainer: formContainerStyles(theme),

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formInput: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formSwitch: {
    margin: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formControlSelect: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  customInputLabel: {
    backgroundColor: "white",
    padding: "0 5px",
    transform: "translate(14px, -8px) scale(0.75)",
    border: "none",
  },
  customInputLabelDisabled: {
    backgroundColor: "white",
    padding: "0 5px",
    transform: "translate(14px, -8px) scale(0.75)",
    border: "none",
    color: theme.palette.text.disabled,
  },
}));
