import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

function SetDefaultButton({ item, mutation }) {
  return (
    <LoadingButton
      loading={mutation.isLoading}
      variant="outlined"
      onClick={() => mutation.mutate(item)}
      color="success"
      size="small"
    >Set Default</LoadingButton>
  );
}

export default SetDefaultButton;
