import { Container, Typography } from "@mui/material";
import { useStyles } from "../themes/theme";

export default function Unauthorized({ config }) {
  const classes = useStyles();

  return (
    <Container className={classes.formContainer}>
      <Typography variant="h4" component="h4">
        You do not have access to this page.
      </Typography>
      <Typography variant="subtitle1">
        Please contact your administrator if you believe this is in error.
      </Typography>
      <Typography variant="body1">
        Contact:{" "}
        <a href={`mailto:plaza-devops@pricer.com`}>plaza-devops@pricer.com</a>
      </Typography>
    </Container>
  );
}
